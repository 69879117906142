import React from 'react';
import './accordion.css';


function FAQBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className='row p-4' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/FAQ_top.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>FAQs</b></p>
                        <p className='h1'><b>LEARN MORE</b></p>
                        <p>
                            We understand that you might have question how Hertz 24/7 works, concerms about your invoices or simply would like to know what is required to register. This page will answer those questions.
                        </p>



                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <br /><br />

                    <p><a href="#reg" style={{ textDecoration: 'none' }}>Registration</a></p>
                    <hr />
                    <p><a href="#book" style={{ textDecoration: 'none' }}>Bookings</a></p>
                    <hr />
                    <p><a href="#yj" style={{ textDecoration: 'none' }}>During Rental</a></p>
                    <hr />
                    <p><a href="#bfi" style={{ textDecoration: 'none' }}>Invoicing & Insurance</a></p>
                    <hr />
                </div>

                <div className="col-xs-12 col-md-9" style={{ textAlign: 'left' }}>
                    <br /><br />


                    <div className="accordion p-4" id="accordionFlushExample">
                        <br />
                        <p id="reg"><b>Register</b></p>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>What is Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Hertz 24/7® gives you the convenience and flexibility of having access to a wide range of vans, for as long or as little as you like. You can rent from just an hour right up to a week. You can also pick up at any hour of day or night. Join for just a £5 annual membership fee, then pay only for your rentals. What's more, insurance and maintenance are included in the rate. The annual membership fee will be charged upon enrolling for new members and contributes to the provision of security validation at the start of your membership and sanitisation of vehicles hired throughout your membership. NOTE: Hertz 24/7 customers who are a valid Costco member do not have to pay the annual membership fee.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>What details do you require?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">In order to join Hertz 24/7 you’ll need to provide the following details:<br />
                                    · A valid driving licence in your own name<br />
                                    · A credit or debit card in your own name<br />
                                    · Your own mobile phone number and your own personal email address.<br />
                                    We then carry out the necessary ID checks before confirming your registration.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>How will I know if my registration has been accepted?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">We’ll send you an email or text to confirm that we’ve received your registration documents and contact you once your application has been processed.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>What happens if my contact details change?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Just log into your Hertz 24/7 profile in the mobile app and amend your details.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Will my membership expire?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Your membership will be for 1 year and will renew automatically on your membership anniversary date and your account will be charged £5 on that date. We will notify you of this in advance. Just as long as your driving licence is valid and you continue to meet our rental requirements. Other terms and conditions may apply.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading6">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                                    <b>Can I cancel my membership and/or its automatic renewal?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse6" className="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Your membership runs for 12 months from the date of your enrolment (the “Membership Term”) and then automatically renews for further 12 month periods (each a “Renewal Term”) unless you cancel your membership or renewal. You can cancel your membership and/or its automatic renewal any time during your Membership Term or a Renewal Term by visiting the Hertz 24/7 App and tap My Profile, Loyalty Programs and then Deactivate My Membership. Please be aware that: You can cancel your membership 14 days before the renewal date of your membership and you will not be charged. You will not be able to make new bookings until the membership is reactivated. If you cancel your membership less than 14 days before the end of your Membership Term or any Renewal Term your membership will automatically renew and continue until the end of the next Renewal Term and the membership fee will be charged and will be non-refundable. At the end of this Renewal Term your membership will be deactivated and will not automatically renew again and no further membership fee will be payable, unless you join again.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading7">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                                    <b>How old do I have to be to hire a vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse7" className="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">You must be 21 years of age or over to hire and drive one of our vehicles. You also need to have held a full driving licence for at least a year and have had no suspensions in the last 5 years.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading8">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                                    <b>Can other drivers, such as my family, share my membership?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse8" className="accordion-collapse collapse" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. The membership is personal and exclusive to you. Please feel free to recommend us though.</div>
                            </div>
                        </div>

                        <br />
                        <p id="book"><b>Booking</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                    <b>What if the vehicle I’d like is already booked?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse9" className="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">If the vehicle you would like to book isn’t available at your preferred location, please pick an alternative time or date, or check availability at other locations nearby.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                    <b>How will I know if my reservation is confirmed?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse10" className="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">We will email you the reservation confirmation and the confirmed reservation is also visible in the mobile app.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                    <b>Are there any fees for changing or cancelling my booking?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">You can change or cancel your booking up to 6 hours before your vehicle pick-up time free of charge. If it’s within 6 hours of pick up, you’ll be charged for the reserved period, up to a maximum of one day's fee.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                    <b>Is my payment secure?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes. Our payment processor is Stripe. We also follow all appropriate Data Protection legislation regarding personal and financial data.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                    <b>How will I be charged when I book a Hertz 24/7 rental?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">We will preauthorise up to 200% of the initial charges (48) hours before you start your rental or immediately if your rental commences within 48 hours. The booking will only be confirmed once authorisation has been successfully obtained. Preauthorisation’s will be released when the invoice is issued. We will deduct the charges and return the remainder. If the rental cost exceeds the deposit, we will capture the exceeded amount. Please note, any delay in the deposit being returned will be from your credit or debit card issuing bank.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading14">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                    <b>How far in advance can I book a vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Depending on the rental station, between three and six months.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSC">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSC" aria-expanded="false" aria-controls="flush-collapseSC">
                                    <b>SuperCover UK</b>
                                </button>
                            </h2>
                            <div id="flush-collapseSC" className="accordion-collapse collapse" aria-labelledby="flush-headingSC" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    If you opted for, or if your rate includes Collision Damage Waiver (CDW) and Theft Protection (TP), you may also wish to purchase an additional protection, "SuperCover", to waive or further reduce your already limited liability in case of damage to, or theft of the Hertz vehicle , including those losses resulting from collision, vandalism, theft or attempted theft, provided the vehicle is used in accordance with the terms and conditions of the Rental Agreement.
                                    <br /><br />
                                    <p>Please note the terms and conditions and limitations below which apply to SuperCover.</p>                                    
                                    <p>SuperCover does not cover:</p>
                                    <p>1. Accessories you rent in addition to the vehicle (e.g. Hertz NeverLost and other portable navigation units, portable WiFi units, child seats, etc.). You will be charged for replacement of these items if they are lost or damaged for any reason.</p>
                                    <p>2. Removable parts of the vehicle if they are missing at time of return such as keys and key fobs, luggage covers and nets, charging cables for PHEV or EV’s, warning vests and triangles, SD GPS cards, spare wheels, tools and tyre inflation devices, 1st aid boxes, fire extinguishers, etc. You will be charged for replacement of these items unless the vehicle was involved in an accident or third party theft.</p>
                                    <p>Please note your liability for damage to, or loss of, the vehicle will not be waived or reduced in the following circumstances and you will remain liable for the full loss and/or damage:</p>
                                    <p>1. where you or the authorised driver are in breach of your obligations under the Rental Agreement (your attention is particularly drawn to the Rental Restrictions section of the Rental Terms);</p>
                                    <p>2. if the loss or damage is caused intentionally;</p>
                                    <p>3. if the driver was not authorised by Hertz as the main or additional driver; or</p>
                                    <p>4. if the main or the additional driver is negligent (for example failure to assess the vehicle’s height, driving on unsuitable road conditions, improper use of the vehicle, contribution to damage to/theft of the vehicle) and in particular, but not limited to, the following circumstances:</p>                                    
                                    <ul>
                                        <li>Striking overhead or overhanging objects; </li>
                                        <li>Driving into a barrier that is too low for the vehicle to pass beneath;</li>
                                        <li>Driving into a barrier in a car park before it fully opens;</li>
                                        <li>Driving on a road in bad condition without due care resulting in damage to the undercarriage;</li>
                                        <li>Driving on a beach causing damage by salt water and/or sand;</li>
                                        <li>Driving through flooded roads causing damage to the engine;</li>
                                        <li>Putting wrong fuel in the vehicle or otherwise contaminating the fuel;</li>
                                        <li>Damage occurring as a result of ignoring a warning light;</li>
                                        <li>Burning a clutch (which requires persistent ill use) or using the handbrake incorrectly;</li>
                                        <li>Damage to the wheel rim caused by driving with a flat tyre;</li>
                                        <li>Fitting unauthorised objects to the interior or exterior of the vehicle;</li>
                                        <li>Carrying especially dirty or smelly materials that require extra cleaning costs or that damage or burn the interior;</li>
                                        <li>Damage resulting from locking the keys in the vehicle or losing the keys;</li>
                                        <li>Damage resulting from leaving the windows open;</li>
                                        <li>Loss of the vehicle caused by failure to use the anti-theft system (if provided);</li>
                                        <li>Loss of the vehicle when you are unable to return the key.</li>
                                    </ul>
                                    <p>SuperCover is not available for customers on business contract rate rentals unless there is a CDP code present that indicates that this option is applicable.</p>
                                    SuperCover is not available for the following vehicle groups: <br/>                                  
                                    (A5) Porsche Boxster 718 (C5) Porsche Cayenne S (D5) R. Rover Sport Supercharged (E5) Audi R8 Spider (F5) Bentley GTC Speed (G5) Porsche 992 4S (H5) Audi Q7 TDI Quattro (I5) Mercedes V Class Extra LWB (J5) Porsche Panamera 4S (K5) Land Rover Range Rover Velar (L5) R. Rover Autobiography LWB (X5) Mercedes C63 AMG Commercial vans and trucks (except 24/7 rentals)<br /><br />

                                    <p>SuperCover is offered in most locations It is not included in the rental rate but can be purchased for the additional amount shown in the reservation and booking process or quoted at the counter at the time of pick up. You can book SuperCover online but it is payable at the counter at the time of pick up (or via the app/website if you are booking a 24/7 rental).</p><br/>

                                    24/7/ Rentals:<br/>
                                    For 24/7 rentals, please be aware that purchasing SuperCover does not eliminate the excess – in the event of an accident or theft you will still be required to pay an excess but this will be a lower amount than where SuperCover does not apply. For your convenience we offer two options for 24/7, SuperCover Light (this has a lower hourly price but a higher excess amount applies) and SuperCover Premium (this has a greater hourly price but a lower excess amount applies). Please see the prices and excess amounts in the booking flow. The restrictions above apply to both products.
                                </div>
                            </div>
                        </div>


                        <br />
                        <p id="yj"><b>Your journey</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading15">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                    <b>How to unlock the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The Hertz 24/7 app will be your digital key. To open your car, press the 'Unlock' button on the drive tab during your rental. Note: you will not be able to open the car until the rental period has officially started, inspection has been completed and the contract signed. Make sure you have **Bluetooth** enabled and are within 5 meters of the vehicle. **If you get Bluetooth activation error on iOS (&gt; 13) although your Bluetooth is on, please go to 'Settings' app then into HERTZ 24/7 section and enable the Bluetooth option**</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading16">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                    <b>Where will I find the keys?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">You’ll find the keys inside the vehicle on a tether under the steering wheel. If not there please check the other compartments.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading17">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                    <b>How do I start the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">On vehicles with an ignition lock, you will find the ignition key in the glove compartment or on a chain near the steering wheel. Depress the brake pedal (brake & clutch pedal for manual transmission) and press the start button or start the engine with the key. If the vehicle does not start, the immobiliser might be on. In the Hertz 24/7 app on the Drive screen, press the Power button to release the immobiliser. The vehicle should start now. NOTE: There is no separate start button on TESLA and POLESTAR models.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading18">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                    <b>What if the vehicle doesn't start?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The vehicle has a security feature that engages the immobiliser if the ignition is off for more than 2 minutes. To restart the engine, if immobilised, remove the key from the ignition, use the app to remove the immobiliser by pressing the 'power' icon on the drive screen (the icon between the lock/unlock icons). Alternatively you can lock and unlock the vehicle through the app. If the vehicle is still doesn’t start, contact to our Member Care Center team. They'll either fix the problem remotely or find another vehicle for you to use nearby.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading19">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                    <b>How do I find the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Your Booking Confirmation includes details on where to find the vehicle. This information is also available to you online via the app. Please ensure you check the pick-up location’s opening hours and any car park restrictions before you book your vehicle. If you intend to leave your own, private vehicle at the site please register this with partner staff at the location.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading20">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                    <b>What if the vehicle I booked isn't there?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Please be sure to check the immediate surrounding area. If you’re still unable to locate your vehicle, please contact our Member Care Center. You’ll find the number in the app and you’ll be offered an alternative vehicle.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading21">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                    <b>What happens if I return the vehicle late?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">If you overrun your allocated time slot without extending your rental beforehand, you’ll be charged at a higher hourly rate for the additional time you use the vehicle, this varies by vehicle and location. You will also be charged a standard late fee.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading22">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                    <b>Does it matter if I pick up the vehicle late?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>For each rental there is a period of 6 hours within you can pick up the vehicle. After 6 hours, the rental will automatically cancel however you will be charged the full rental amount up to 1 day.</p>
                                    <p>If you can not make your start time within the hour, you can adjust the rental at any time*.<br />You can also cancel rental bookings up to 6 hours before the start without charge. However if you cancel closer than 6 hours to the rental start, the full rental charge will be invoiced up-to 1 day.</p>
                                    <p>*subject to availability of vehicle.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading23">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                    <b>Who pays for the fuel cost?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Fuel will be charged on a pence per mileage basis, displayed at the time of booking. All vehicles should come with a fuel card under the sun visor and at least a quarter tank of fuel at the beginning of the rental. You should return the vehicle this way too. If the fuel card is missing at the beginning of the rental please contact us using the telephone number in the Help section of the app. There is a £50 per transaction limit on the fuel card provided. Fuel cards can be used at Shell, Esso or Texaco fuel stations and the pin code displayed in the app will be required when paying for fuel.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading24">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                    <b>What if the vehicles fuel tank is empty or is less than a quarter full?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">If the tank is less than a quarter full, please let us know and refuel using the fuel card provided. You’ll find the fuel card under the sun visor and the fuel card access PIN code in the mobile app. If there’s no fuel at all in the vehicle, or if you could not find the fuel card, please contact our Member Care Center.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading25">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                    <b>What shall I do if I’ve exceeded the fuel card limit or paid for fuel without the card?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse25" className="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">When refuelling the vehicle please ensure that you do not exceed the limit shown on the fuel card. If you do exceed the limit you will need to pay for the fuel yourself. You will need to email the receipt to Hertz 24/7 Member Care Center in order to receive a refund for fuel you have paid for yourself. Please be aware that this may take several days to process and is subject to a processing fee of £15 to contribute towards the costs and expenses relating to the refund where you have failed to follow the correct refuelling process.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading26">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                    <b>Can I smoke inside the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. Please don’t smoke inside any Hertz 24/7 vehicle. Our vehicles have been classified as strictly non-smoking areas since 1 May 2013. It’s part of our drive to provide a safe, clean fleet for all of our employees and customers. Thank you for your consideration. A cleaning fee of £50 will be added to your invoice if we find evidence of smoking inside the vehicle during your rental period.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading27">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                    <b>What happens if I get a speeding ticket or parking fine?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">As you would expect, things like tolls, speeding tickets, parking fines and ferry fees are your responsibility to pay.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading28">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse28">
                                    <b>Do I have to pay any Congestion Charges?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse28" className="accordion-collapse collapse" aria-labelledby="flush-heading28" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. We’ve taken care of that already.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading29">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse29" aria-expanded="false" aria-controls="flush-collapse29">
                                    <b>What if I find something in the vehicle or leave something of my own behind?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse29" className="accordion-collapse collapse" aria-labelledby="flush-heading29" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Not a problem. Please just contact our team and we’ll take care of retrieving your item OR finding the previous renter who left the item.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading30">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                    <b>Do you provide child seats?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse30" className="accordion-collapse collapse" aria-labelledby="flush-heading30" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. However, you’re very welcome to use your own.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading31">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                    <b>Am I allowed to carry pets in the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse31" className="accordion-collapse collapse" aria-labelledby="flush-heading31" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. Pets aren’t permitted in our vehicles – except for guide dogs and hearing dogs.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading32">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                    <b>Do I have to clean the vehicle when I return it?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse32" className="accordion-collapse collapse" aria-labelledby="flush-heading32" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes. For the benefit of the next customer, please make sure the inside of the vehicle is in a clean condition at the end of your rental.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading33">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                    <b>What if the vehicle isn’t clean inside or out?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse33" className="accordion-collapse collapse" aria-labelledby="flush-heading33" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">We do ask our customers to leave the vehicle clean and tidy at the end of each rental. We also maintain our fleet of vehicles on a regular basis. However, please do let us know if you’re unhappy with the vehicles condition.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading34">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                    <b>Can I return the vehicle early?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse34" className="accordion-collapse collapse" aria-labelledby="flush-heading34" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes. You can return the vehicle at any time during your rental period. However, you’ll still be charged for the whole booking period.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading35">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                    <b>How do I extend my rental?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse35" className="accordion-collapse collapse" aria-labelledby="flush-heading35" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">You can easily extend your rental via the app subject to availability of vehicles. Follow the steps after tapping the 'Extend rental' button on the drive tab during your rental.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading36">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                    <b>Where should I return the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse36" className="accordion-collapse collapse" aria-labelledby="flush-heading36" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Please return the vehicle to the Hertz 24/7 designated parking bay at the location.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading37">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                    <b>What if my designated parking bay is already taken?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse37" className="accordion-collapse collapse" aria-labelledby="flush-heading37" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Please park the vehicle in the bay closest to the Hertz 24/7 bay.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading38">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                    <b>What if the vehicle I've booked is returned late by the customer before me?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse38" className="accordion-collapse collapse" aria-labelledby="flush-heading38" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">We’ll always try to reach you in time to let you know. However, if you haven’t heard from us, please contact us ASAP and we’ll arrange an alternative vehicle.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading39">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                    <b>Should I ever use the keys to lock and unlock the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse39" className="accordion-collapse collapse" aria-labelledby="flush-heading39" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. To do so may cause issues during your rental. The keys must be left on the tether inside the vehicle at all times. Please use the Hertz 24/7 app to lock and unlock the vehicle during your rental. NOTE: During inactivity, the vehicle immobiliser might come on. To release this, please press the 'power' button on the drive screen on the app.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading40">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                    <b>How are your vehicles maintained?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse40" className="accordion-collapse collapse" aria-labelledby="flush-heading40" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A team of dedicated professionals looks after our fleet of Hertz 24/7 vehicles. In addition to our usual scheduled maintenance, we carry out a monthly 40-point quality check on every vehicle.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading41">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                    <b>What do I do if I breakdown?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse41" className="accordion-collapse collapse" aria-labelledby="flush-heading41" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Please call our support team. You'll find our phone number on the contact us page in the app. The AA can also be reached directly on 0800 289 494</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading42">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                    <b>What do I do if I have an accident / incident?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse42" className="accordion-collapse collapse" aria-labelledby="flush-heading42" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Please contact the Hertz 24/7 team immediately using the contact details in the 'help' section of the mobile app. We’ll then take care of everything. Just be sure to get the details of the third party involved in the accident and to take photos of the damage done to both vehicles. Failure to report any incident may invalidate your insurance cover.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading43">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse43" aria-expanded="false" aria-controls="flush-collapse43">
                                    <b>What should I do if the vehicle is stolen?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse43" className="accordion-collapse collapse" aria-labelledby="flush-heading43" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">You must notify us immediately via phone. You will find the phone number in the contact section.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading44">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse44" aria-expanded="false" aria-controls="flush-collapse44">
                                    <b>Can I use Hertz 24/7 in any other countries?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse44" className="accordion-collapse collapse" aria-labelledby="flush-heading44" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes. If you’re registered with us you can currently use Hertz 24/7 at any participating location in the UK, France, Germany, Belgium and Portugal.</div>
                            </div>
                        </div>

                        <br />
                        <p id="bfi"><b>Billing, fees and insurance</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading45">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse45" aria-expanded="false" aria-controls="flush-collapse45">
                                    <b>Do I pay a fee if the vehicle is towed away or clamped?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse45" className="accordion-collapse collapse" aria-labelledby="flush-heading45" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">We'll pay the fine at first and then add it to your invoice, together with a £42 admin fee.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading46">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse46" aria-expanded="false" aria-controls="flush-collapse46">
                                    <b>Will I be asked to pay a late return fee if the vehicle breaks down?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse46" className="accordion-collapse collapse" aria-labelledby="flush-heading46" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. In the highly unlikely event of a breakdown, you won’t be liable for any extra charges (unless you caused the breakdown of course). 24-hour Emergency Roadside Assistance is included in your booking.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading47">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse47" aria-expanded="false" aria-controls="flush-collapse47">
                                    <b>Are there any extra charges I need to be aware of?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse47" className="accordion-collapse collapse" aria-labelledby="flush-heading47" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes. There’s a mileage rate on every booking. If you damage the vehicle, there’s an insurance excess to pay. This is £1000 on cars or £1200 on vans. Gross Negligence (e.g. roof damage, ad-blue or fuel contamination) and Vehicle Abuse is not covered by the Collision Damage Waiver and Third Party Insurance that are covered in the vehicle rental rate</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading48">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse48" aria-expanded="false" aria-controls="flush-collapse48">
                                    <b>Will my personal car insurance cover my damage fee?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse48" className="accordion-collapse collapse" aria-labelledby="flush-heading48" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. Your personal car insurance isn’t valid for Hertz 24/7 vehicles. Our vehicles are only covered by the insurance policies we provide or make available in relation to your booking.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading49">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse49" aria-expanded="false" aria-controls="flush-collapse49">
                                    <b>Do I have to pay any excess?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse49" className="accordion-collapse collapse" aria-labelledby="flush-heading49" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">In the event of vehicle damage, loss or theft you’ll be liable to pay an excess of £1000 for cars or £1200 for vans. If the repair is less than the excess, we’ll refund you the difference, minus an admin fee.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading50">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse50" aria-expanded="false" aria-controls="flush-collapse50">
                                    <b>What does the insurance on my booking cover me for?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse50" className="accordion-collapse collapse" aria-labelledby="flush-heading50" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The insurance covers you for damage to the vehicle, over and above the excess amount. The excess is the maximum you are liable to pay in the event of a claim. Provided you follow the terms and conditions of Your Rental Agreement, you'll also be protected by Third Party Liability Insurance, which is included as standard and is required by law. This covers you for damage to other vehicles, other property and injured parties in the event of an accident. If vehicle loss, damage or theft is caused intentionally. If you put the wrong fuel into the vehicle, contaminate the fuel tank or contaminate the Ad blue tank all such damage will be categorised as gross negligence and you will be liable for the cost of repair. If the vehicle is stolen you will only be charged the excess amount.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading51">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse51" aria-expanded="false" aria-controls="flush-collapse51">
                                    <b>Can I rely on my credit card provider’s insurance scheme to cover any damage?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse51" className="accordion-collapse collapse" aria-labelledby="flush-heading51" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">It’s best to check with your credit card provider before you make your Hertz 24/7 booking as different credit cards have different insurance protection schemes.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/App_Banner-new-format%20EN.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div>


        </div>


    </>
    );
}

export default FAQBody;