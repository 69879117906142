import React from 'react';


function FAQBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{  width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/FAQ_top.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>FAQs</b></p>
                        <p className='h1'><b>Mais sobre a Hertz 24/7®</b></p>
                        <p>

                        Caso tenha dúvidas relacionadas com o serviço, por favor consulte as nossas FAQs onde poderá encontrar as suas respostas.
                        </p>



                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <br /><br />
                   
                    <p><a href="#reg" style={{textDecoration : 'none'}}>Registo</a></p>
                    <hr />
                    <p><a href="#book" style={{textDecoration : 'none'}}>Reservar</a></p>
                    <hr />
                    <p><a href="#yj" style={{textDecoration : 'none'}}>A sua viagem</a></p>
                    <hr />
                    <p><a href="#bfi" style={{textDecoration : 'none'}}>Faturação, custos e seguros</a></p>
                    <hr />
                </div>

                <div className="col-xs-12 col-md-9" style={{ textAlign: 'left' }}>
                    <br /><br />
                    
                    <br />
                    <p id="reg"><b>Registo</b></p>
                    <div className="accordion p-4" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>O que é a Hertz 24/7®?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A Hertz 24/7® oferece-lhe conveniência e flexibilidade para alugar uma carrinha comercial à hora. É um serviço disponível nas lojas parceiras IKEA, Leroy Merlin, Brico Depôt e Allmat, eliminando o stress e custos desproporcionais que surgem com a necessidade de transporte de bens pesados ou de grandes dimensões.<br /><br />O serviço está inteiramente disponível na app Hertz 24/7®. Evite as filas e o tempo de espera!</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Quais são os requisitos mínimos?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Para usar o serviço 24/7, terá de ter na sua posse:<br />
                                    · Uma carta de condução em seu nome, válida há mais de um ano<br />
                                    · Um documento de identificação (cartão de cidadão ou passaporte)<br />
                                    · Um cartão de crédito ou de débito em seu nome (consulte condições)<br />
                                    · O seu próprio número de telemóvel e endereço de correio eletrónico<br />
                                    · Um smartphone Android ou iPhone com ligação à internet, Bluetooth e geolocalização<br/><br/>
                                    Após submeter a documentação necessária, a nossa equipa irá validar tudo para poder dar início à sua primeira reserva!
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>O meu registo está validado?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Após submeter toda a documentação, aguarde que a nossa equipa confirme os dados. Se vir a mensagem "A sua conta está validada" no seu "Perfil", poderá criar uma reserva!</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>O que acontece se os meus dados mudarem (morada, carta de condução, etc)?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Basta aceder ao seu perfil Hertz 24/7® na App e voltar a submeter toda a documentação que sofreu alterações.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>A minha adesão vai expirar?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A sua adesão é válida por um ano, sendo renovável automaticamente pelo mesmo período. Como membro, poderá cancelar a sua adesão a qualquer momento mas, nesse caso, deverá notificar a Hertz 24/7® com a antecedência mínima de 30 dias.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading6">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                                    <b>Qual é a idade mínima para usar o serviço?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse6" className="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Para alugar e conduzir uma viatura Hertz 24/7®, é necessário ter 25 anos de idade ou mais. No entanto, no caso de ter idade compreendida entre 21 e 24 anos de idade, cabe-lhe ainda o pagamento de um suplemento de condutor jovem, por cada aluguer realizado, que terá um custo de 3€ na primeira hora, e 1,5€ por cada meia hora seguinte.</div>
                            </div>
                        </div>

                        <br />
                        <p id="book"><b>Reservar</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading8">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                                    <b>E se a viatura não estiver disponível?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse8" className="accordion-collapse collapse" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Se a viatura que pretende reservar não estiver disponível na localização que selecionou, escolha uma hora ou data/loja alternativa.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                    <b>A minha reserva foi confirmada?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse9" className="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Além de ser notificado pela app, a confirmação da reserva é enviada por correio eletrónico.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                    <b>Posso alterar/cancelar  a minha reserva? Vou incorrer em custos adicionais?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse10" className="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pode alterar a sua reserva até 6 horas antes do início do seu aluguer, sem qualquer custo adicional. Assim que o aluguer começar, apenas pode prolongar a sua duração.<br/><br/>Para evitar que lhe seja cobrado o valor total da reserva, poderá cancelá-la até 2 horas antes do início do aluguer. Se não cancelar com a antecedência mínima de 2 horas, ser-lhe-á cobrado o valor correspondente a 8 horas de aluguer ou o valor total da reserva, consoante aquele que corresponda ao valor inferior.<br/><br/>Pode modificar a sua reserva na secção "Reservas" da app.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                    <b>O meu pagamento é seguro?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sim, seguimos toda a legislação adequada em matéria de proteção de dados, relativamente a dados pessoais e financeiros.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                    <b>Como serão processados os encargos de uma reserva Hertz 24/7®?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ao efetuar a reserva, será estabelecida uma pré-autorização no valor de 150 €,  48 horas antes do início do aluguer. Caso o aluguer tenha início num período inferior a 48 horas, a autorização de 150 € será feita no ato da reserva. A pré-autorização correspondente a 200% do valor da reserva mencionada anteriormente, determina-se pelos valores já confirmados pelo membro no ato da reserva. Em qualquer caso, trata-se unicamente de uma pré-autorização ou garantia e só serão cobrados os valores aferidos no fim do aluguer.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                    <b>Com que antecedência posso reservar uma viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Com muita antecedência. De facto, pode reservar uma viatura até dois meses de antecedência.</div>
                            </div>
                        </div>
                        <br />
                        <p id="yj"><b>A sua viagem</b></p>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading14">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                    <b>Como desbloquear a viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A app Hertz 24/7® servirá como a sua chave digital.<br/><br/>Após formalizar o início do aluguer selecionando "Iniciar aluguer", para destrancar as portas da sua viatura, certifique-se que o seu smartphone se encontra num raio de 5 metros com o Bluetooth ligado. Depois, aceda à sua reserva e selecione "Obter Chave Digital" no separador "conduzir". Quando estiver ligado à viatura, terá acesso aos controlos para trancar/destrancar as portas.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading15">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                    <b>Onde estão as chaves da viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">As chaves encontram-se no interior do carro, junto ao volante ou dentro do porta-luvas. Caso contrário, verifique os outros compartimentos.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading16">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                    <b>Como iniciar a marcha?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    Dentro da viatura, liberte o travão de mão (ou pé no modelo Vito), faça pressão nos pedais do travão e embraiagem e rode a chave (ou use o botão Start/Stop em certos modelos).<br /><br />Na eventualidade de a viatura não iniciar, é possível que o "imobilizador" esteja ligado. Na app Hertz 24/7®, aceda à secção "Conduzir" e selecione o botão do meio para libertar o imobilizador.<br/><br/>Caso a viatura não arranque, o nosso Contact Center está à sua disposição para resolver potenciais entraves.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading17">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                    <b>Como encontrar a viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Através da app, encontrará uma secção dedicada com um mapa a mostrar a localização exata da viatura.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading18">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                    <b>E se o veículo que reservei não estiver lá?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Caso confirme que está na zona dedicada à Hertz 24/7® e o seu modelo está ausente, por favor contacte o nosso Contact Center.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading19">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                    <b>O que acontece se eu devolver a viatura com atraso?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">É essencial que devolva a sua viatura dentro da data/hora programada para a sua reserva, pois corre o risco de ocupar tempo de aluguer do próximo cliente que esteja à espera.<br /><br />Se prever algum atraso, por favor avise-nos. Se nos der conhecimento prévio que vai devolver a viatura após a hora indicada, ser-lhe-á cobrado o valor de 15€ adicionalmente ao custo do aluguer. A devolução da viatura após a hora indicada sem aviso prévio, terá um custo adicional equivalente a 125% do valor de reserva, por cada período de 15 minutos extra + o custo do aluguer.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading20">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                    <b>Posso levantar a viatura mais tarde/devolver mais cedo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Para cada aluguer, há um período de 6 horas durante o qual pode levantar o veículo. Após 6 horas, o aluguer será automaticamente cancelado, mas ser-lhe-á cobrado o montante total do aluguer até 1 dia.</p>
                                    <p>Se não conseguir chegar à sua hora de início dentro de uma hora, pode ajustar o aluguer em qualquer altura*.<br />Também pode cancelar as reservas de aluguer até 6 horas antes do início do aluguer sem custos. No entanto, se cancelar com menos de 6 horas de antecedência do início do aluguer, será facturada a totalidade do custo do aluguer até 1 dia.</p>
                                    <p>*sujeito à disponibilidade do veículo.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading21">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                    <b>Quem paga o custo do combustível?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Deverá assegurar-se que deixa o mínimo de ¼ do depósito cheio para o próximo cliente. Consulte os <a href='https://resources.hertz247.ufofleet.com/publishing/documents/247TCPTPT.pdf' target='_blank' rel="noreferrer" >Termos e Condições</a> para mais informação.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading22">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                    <b>Devo abastecer a viatura antes de iniciar o aluguer?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A sua reserva inclui 40 Km gratuitos.<br/>Custo por Km extra: 0,35€/Km.<br/><br/>Caso a viatura tenha muito pouco combustível no inicio do aluguer, deve contactar o Contact Center.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading23">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                    <b>Posso fumar dentro da viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Se receber uma multa por excesso de velocidade, estacionamento indevido ou por qualquer outra infração, é da sua responsabilidade o pagamento do valor correspondente.<br/><br/>Deve pagar todas as multas exatamente da mesma forma que as pagaria se estivesse a utilizar a sua própria viatura. Ser-lhe-á cobrado o valor total da multa acrescido de uma taxa administrativa no valor de 25€.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading24">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                    <b>E se eu encontrar algo no veículo ou esquecer-me de um pertence?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Não se preocupe. Basta contactar a nossa equipa e nós tentaremos recuperar o seu artigo OU de informar o condutor anterior.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading25">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                    <b>Fornecem cadeiras para crianças?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse25" className="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Não. No entanto, caso já possua uma, pode utilizar, caso a viatura por si seleccionada permita sistema de fixação compativel (não garantido).</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading26">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                    <b>São permitidos animais dentro das viaturas?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pedimos desculpa, mas os únicos animais permitidos nas nossas viaturas são os cães-guia para cegos.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading27">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                    <b>Tenho de lavar a viatura quando a devolver?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Para benefício de todos os membros, por favor assegure-se que devolve a viatura nas mesmas condições em que a encontrou.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading28">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse28">
                                    <b>E se a viatura não estiver limpa por dentro ou por fora?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse28" className="accordion-collapse collapse" aria-labelledby="flush-heading28" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pedimos aos nossos clientes que deixem o veículo limpo e arrumado no final de cada aluguer. A nossa frota de viaturas é objeto de uma manutenção regular. No entanto, se estiver insatisfeito, não hesite em contactar-nos.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading29">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse29" aria-expanded="false" aria-controls="flush-collapse29">
                                    <b>Onde devo devolver a viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse29" className="accordion-collapse collapse" aria-labelledby="flush-heading29" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Por favor, devolva a viatura no local Hertz 24/7® identificado no parque de estacionamento da loja.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading30">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                    <b>E se o meu lugar de estacionamento já estiver ocupado?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse30" className="accordion-collapse collapse" aria-labelledby="flush-heading30" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Por favor, estacione na zona mais próxima do local Hertz 24/7®, na qual seja permitido parquear legalmente.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading31">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                    <b>O que acontece se a viatura que reservei for devolvida com atraso pelo cliente anterior?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse31" className="accordion-collapse collapse" aria-labelledby="flush-heading31" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Tentaremos sempre contactá-lo a tempo de o informar. Caso já esteja à espera durante muito tempo, não hesite em contactar-nos.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading32">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                    <b>Devo utilizar as chaves para trancar e destrancar o veículo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse32" className="accordion-collapse collapse" aria-labelledby="flush-heading32" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Não. Se o fizer, pode causar problemas durante o aluguer. As chaves devem ser deixadas sempre dentro da viatura. Use a app Hertz 24/7® para bloquear e desbloquear a viatura durante o seu aluguer. NOTA: Após um período de inatividade, o imobilizador do veículo pode ligar-se. Para o desativar, prima o botão do meio em "Conduzir".</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading33">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                    <b>Como é feita a manutenção das viaturas?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse33" className="accordion-collapse collapse" aria-labelledby="flush-heading33" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Uma equipa de profissionais dedicados cuida da frota Hertz 24/7®. Para além da manutenção habitual e devidamente programada, efetuamos periodicamente um controlo de qualidade e reabastecemos as viaturas disponíveis para aluguer, caso o depósito esteja quase vazio.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading34">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                    <b>O que fazer em caso de avaria?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse34" className="accordion-collapse collapse" aria-labelledby="flush-heading34" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Estacione a viatura num lugar seguro e contacte o nosso Contact Center assim que possível. Navegue para a secção "Ajuda" na app para encontrar os contactos do nosso Contact Center.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading35">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                    <b>O que devo fazer se tiver um acidente/incidente?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse35" className="accordion-collapse collapse" aria-labelledby="flush-heading35" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Contacte o nosso Contact Center assim que possível. Navegue para a secção "Ajuda" na app para encontrar os contactos do nosso Contact Center. Deve obter os dados do terceiro envolvido no acidente e tirar fotografias dos danos causados em ambos os veículos. Uma falha ao registar quaisquer danos pode invalidar o seu seguro.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading36">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                    <b>O que devo fazer em caso de furto da viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse36" className="accordion-collapse collapse" aria-labelledby="flush-heading36" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Deve avisar-nos imediatamente por telefone. Navegue para a secção "Ajuda" na app para encontrar os contactos do nosso Contact Center.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading37">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                    <b>Posso utilizar a Hertz 24/7® noutros países?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse37" className="accordion-collapse collapse" aria-labelledby="flush-heading37" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sim. Se estiver registado na app, pode utilizar o serviço nos restantes países participantes (as condições de utilização podem alterar).</div>
                            </div>
                        </div>

                        <br />
                        <p id="bfi"><b>Faturação, custos e seguros</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading38">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                    <b>Existe um custo adicional se o veículo for rebocado ou bloqueado?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse38" className="accordion-collapse collapse" aria-labelledby="flush-heading38" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sim, além de ficar responsável pelo custo do bloqueio ou reboque, terá de pagar um valor adicional. Consulte os <a href='https://resources.hertz247.ufofleet.com/publishing/documents/247TCPTPT.pdf' target='_blank' rel="noreferrer">Termos e Condições</a> para mais informação.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading39">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                    <b>Em caso de avaria, terei de pagar o custo de entrega tardia?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse39" className="accordion-collapse collapse" aria-labelledby="flush-heading39" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Não. Em caso de avaria, não será responsável por quaisquer encargos adicionais (pode alterar caso tenha causado a avaria). Em caso de cobrança automática comprovadamente indevida, será reembolsado do valor cobrado em excesso.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading40">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                    <b>O meu seguro automóvel pessoal cobre o meu suplemento de danos?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse40" className="accordion-collapse collapse" aria-labelledby="flush-heading40" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Não. O seu seguro automóvel pessoal não é válido para viaturas Hertz 24/7®.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading41">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                    <b>Tenho de pagar alguma franquia? O que cobre?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse41" className="accordion-collapse collapse" aria-labelledby="flush-heading41" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">O membro será responsável por danos, furto ou atos de vandalismo até ao valor da franquia. Consulte os <a href='https://resources.hertz247.ufofleet.com/publishing/documents/247TCPTPT.pdf' target='_blank' rel="noreferrer">Termos e Condições</a> para saber os valores em detalhe.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading42">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                    <b>Posso contar com o seguro do meu fornecedor de cartão de crédito para cobrir eventuais danos?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse42" className="accordion-collapse collapse" aria-labelledby="flush-heading42" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Recomendamos que confirme com o fornecedor do seu cartão de crédito antes de efetuar a sua reserva Hertz 24/7®, uma vez que diferentes cartões de crédito oferecem diferentes sistemas de proteção de seguros.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/App_Banner-new-format%20PT.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div>

        </div>


    </>
    );
}

export default FAQBody;